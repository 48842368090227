
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"

import uuid1 from "uuid/v1"
import SvgIcon from "@/components/SvgIcon.vue"

@Component({
  components: {
    SvgIcon,
  },
})
export default class FLInput extends Vue {
  @Prop({ default: "" }) value!: string
  @Prop({ default: "" }) label!: string
  @Prop({ default: "" }) id!: string
  @Prop({ default: "text" }) type!: string
  @Prop({ default: "" }) iconName!: string
  @Prop({ default: false }) required!: boolean
  @Prop({ default: () => {} }) enterCb!: Function
  @Prop({ default: false }) readonly error!: boolean
  @Prop({ default: "" }) readonly errorText!: string
  @Prop({ default: () => {} }) readonly validate!: Function

  showError = this.error

  get _id(): string {
    return this.id || uuid1()
  }

  inputEvent($event: any) {
    this.$emit("input", $event.target.value)
  }

  onBlur() {
    if (!this.validate || this.error) {
      return this.error
    }
    if (!this.validate()) {
      this.showError = true
    } else {
      this.showError = false
    }
  }
}
