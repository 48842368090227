
// TODO: Look into http://youmightnotneedjs.com/#tabs for tabs
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

import uuid1 from "uuid/v1"
import Modal from "@/components/Modal.vue"
import FLButton from "@/components/base/FLButton.vue"
import { Item } from "@/data/items/itemTypes"
import ItemTemplatePicker from "@/components/gear/ItemTemplatePicker.vue"
import TabBar from "@/components/base/TabBar.vue"

import { defaultItem, makeTempItem } from "./itemUtil"
import { ItemForm } from "./ItemForm.vue"

@Component({
  components: {
    FLButton,
    ItemTemplatePicker,
    Modal,
    TabBar,
    ItemForm,
  },
})
export class ModalAddItem extends Vue {
  @Prop({ default: "" }) title!: string

  tmpGear: Item = defaultItem()

  save() {
    if (!this.tmpGear.name) {
      this.tmpGear.name = "???"
    }
    this.$emit("add-item", this.tmpGear)
  }

  close() {
    this.$emit("close")
  }

  // FIXME: Look into using tabindex instead of a potpurri of booleans
  templateActive = true
  newActive = !this.templateActive
  showTemplate() {
    this.newActive = false
    this.templateActive = true
  }
  showNew() {
    this.newActive = true
    this.templateActive = false
  }

  onTemplatePicked(item: Item) {
    this.tmpGear = makeTempItem({ ...item, id: uuid1() })
    this.showNew()
    this.tabIndex = 1
  }

  tabIndex = 0
  onTabClicked(index: number) {
    this.tabIndex = index
  }

  get tabButtons() {
    return [
      {
        name: this.$t("Template"),
        onClick: () => {
          this.showTemplate()
        },
      },
      {
        name: this.$t("Details"),
        onClick: () => {
          this.showNew()
        },
      },
    ]
  }
}

export default ModalAddItem
