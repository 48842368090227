
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"

import { CharacterData, validateSkills } from "@/data/character/characterData"
import { getAgeType } from "@/age"
import { Age } from "@/types"

import SkillSelector from "@/components/SkillSelector.vue"
import Card from "@/components/Card.vue"

@Component({
  components: { SkillSelector, Card },
})
export class SkillCard extends Vue {
  @Prop({ required: true }) charData!: CharacterData
  @Prop({ default: true }) viewOnly!: boolean

  get isNew() {
    return this.charData.metadata.status === "new"
  }
  get ageType(): Age {
    return getAgeType(this.charData.age, this.charData.kin)
  }
  get skillsValid(): boolean {
    return validateSkills(this.charData)
  }
  get saveStateId() {
    if (this.charData.metadata.status !== "active") return ""
    return "card_skills"
  }
}

export default SkillCard
