
// TODO: Look into http://youmightnotneedjs.com/#tabs for tabs
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

import Modal from "@/components/Modal.vue"
import FLButton from "@/components/base/FLButton.vue"
import { Item } from "@/data/items/itemTypes"
import ItemTemplatePicker from "@/components/gear/ItemTemplatePicker.vue"
import TabBar from "@/components/base/TabBar.vue"

import { makeTempItem } from "./itemUtil"
import { ItemForm } from "./ItemForm.vue"

@Component({
  components: {
    FLButton,
    ItemTemplatePicker,
    Modal,
    TabBar,
    ItemForm,
  },
})
export class ModalEditItem extends Vue {
  @Prop() editItem!: Item
  @Prop({ default: "" }) title!: string

  tmpGear: Item = makeTempItem(this.editItem)

  save() {
    if (!this.tmpGear.name) {
      this.tmpGear.name = "???"
    }
    this.$emit("save", this.tmpGear)
  }

  close() {
    this.$emit("close")
  }
}

export default ModalEditItem
