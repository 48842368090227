import uuid1 from "uuid/v1"

import { Item } from "@/data/items/itemTypes"

export function defaultItem(): Item {
  return {
    bonus: 0,
    bonusType: null,
    equipped: false,
    name: "",
    weight: 1,
    type: "",
    id: uuid1(),
  }
}

export function makeTempItem(orig: Item | null) {
  const item: Item = JSON.parse(JSON.stringify(orig || defaultItem()))
  if (!item.artifactDice) {
    item.artifactDice = [{ sides: 0, color: "green", nbrDice: 0 }]
  }
  return item
}
