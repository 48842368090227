
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"

import FLButton from "@/components/base/FLButton.vue"

interface Tab {
  name: string
  disabled: boolean
  onClick: () => {}
}

@Component({
  components: {
    FLButton,
  },
})
export default class TabBar extends Vue {
  @Prop({ required: true }) buttons!: Tab[]
  @Prop({ default: false }) disabled!: boolean
  @Prop({ required: true }) currentTabIndex!: number

  get currentTabComponent() {
    return this.buttons[this.currentTabIndex].name
  }

  setActiveTab(index: number) {
    if (this.disabled || this.buttons[index].disabled) return
    ;(this.buttons[index].onClick || (() => {}))()
    this.$emit("tab-active", index)
  }
}

// https://jsfiddle.net/chrisvfritz/o3nycadu/ or "Dynamic components Vue" for
// more info
