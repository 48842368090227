import { render, staticRenderFns } from "./GearBackpack.vue?vue&type=template&id=53686654&scoped=true&"
import script from "./GearBackpack.vue?vue&type=script&lang=ts&"
export * from "./GearBackpack.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53686654",
  null
  
)

export default component.exports