
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"

import uuid1 from "uuid/v1"
import SvgIcon from "@/components/SvgIcon.vue"

@Component({
  components: {
    SvgIcon,
  },
})
export class FLRangeSlider extends Vue {
  @Prop({ required: true }) value!: number
  @Prop({ required: true }) max!: number
  @Prop({ default: 0 }) min!: number
  @Prop({ default: 1 }) step!: number

  @Prop({ default: "" }) prefix!: string
  @Prop({ default: "" }) suffix!: string
  @Prop({ default: "" }) id!: string

  uuid = uuid1()
  active = false

  get _id(): string {
    return this.id || this.uuid
  }

  get listId(): string {
    return `${this._id}-list`
  }

  inputEvent($event: any) {
    const value = Number($event.target.value)
    this.$emit("input", value)
  }
}

export default FLRangeSlider
