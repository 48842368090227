
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"
import { CharacterData, CharacterTalent } from "@/data/character/characterData"
import { Item } from "@/data/items/itemTypes"

import FLNumberInput from "@/components/base/FLNumberInput.vue"

@Component({
  components: {
    FLNumberInput,
  },
})
export class AnimalCompanionStats extends Vue {
  @Prop({ required: true }) charData!: CharacterData
  @Prop({ default: false }) viewOnly!: boolean
}
export default AnimalCompanionStats
