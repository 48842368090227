
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"

import { FLButton } from "@/components/base/FLButton.vue"
import { CharData } from "@/data/character/characterData"
import { Item } from "@/data/items/itemTypes"
import ModalConfirm from "@/components/ModalConfirm.vue"
import { GearTable } from "@/components/gear/GearTable.vue"
import { GearRowDetail } from "@/components/gear/GearRowDetail.vue"
import { ModalEditItem } from "src/components/gear/item/ModalEditItem.vue"
import { ModalAddItem } from "src/components/gear/item/ModalAddItem.vue"

import { iconFor, equippable } from "./util"

@Component({
  components: {
    FLButton,
    GearRowDetail,
    GearTable,
    ModalAddItem,
    ModalEditItem,
    ModalConfirm,
  },
})
export class GearInventory extends Vue {
  @Prop({ default: null }) charData!: CharData
  @Prop({ default: false }) viewOnly!: boolean
  @Prop({ default: false }) mountInventory!: boolean

  showAddItemModal = false
  showConfirmDeleteItem = false
  activeEditItem: Item | null = null

  iconFor = iconFor
  equippable = equippable

  get inventory() {
    if (this.mountInventory) {
      return this.charData.mount.inventory
    }
    return this.charData.gear.inventory
  }
  set inventory(newInventory: Item[]) {
    if (this.mountInventory) {
      this.charData.mount.inventory = newInventory
    } else {
      this.charData.gear.inventory = newInventory
    }
  }
  get otherInventory() {
    if (this.mountInventory) {
      return this.charData.gear.inventory
    }
    return this.charData.mount.inventory
  }

  get backpack() {
    return this.inventory.filter((i) => !i.equipped)
  }

  get itemsSelected() {
    return this.inventory.filter((item) => !!item.selected).length > 0
  }

  get moveItemsDisabled() {
    return (
      this.viewOnly ||
      !this.itemsSelected ||
      (!this.mountInventory && !this.hasMount)
    )
  }

  get hasMount(): boolean {
    return !!this.charData.mount.strength && !!this.charData.mount.name
  }

  addItem(item: Item) {
    this.inventory.push(item)
    this.showAddItemModal = false
  }

  moveItems() {
    let newInventory: Item[] = []
    for (let item of this.inventory) {
      if (item.selected) {
        this.otherInventory.push({ ...item, selected: false })
      } else {
        newInventory.push(item)
      }
    }
    this.inventory = newInventory
  }

  dropItems() {
    const newInventory = this.inventory.filter((item) => !item.selected)
    this.inventory = newInventory
    this.showConfirmDeleteItem = false
  }

  get deleteItemsBody() {
    const items = this.inventory
      .filter((item) => item.selected)
      .map((item) => item.name)
      .join(", ")
    return `${this.$t("Drop")} ${items}?`
  }

  handleClickItem(item: Item) {
    if (!item || !!this.viewOnly) return
    this.activeEditItem = item
  }

  updateItem(updatedItem: Item) {
    const newInventory = this.inventory.map((mitem) => {
      if (mitem.id !== updatedItem.id) return mitem
      return updatedItem
    })
    this.inventory = newInventory
    this.activeEditItem = null
  }
}

export default GearInventory
