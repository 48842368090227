
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"

import { SKILLS, SkillObj } from "@/skills"
import { capitalize } from "@/util/util"

import FLButton from "@/components/base/FLButton.vue"
import IconButton from "@/components/base/IconButton.vue"

import FLSelect, { Option } from "@/components/base/FLSelect.vue"

/**
Component for managing mount and animal companion skills

To implement
- Dice roll for skill

*/

export const ADDING_DONE = "adding-done"
export const CANCEL = "adding-cancel"
export const skillInputRanks: Option[] = [
  { id: "1", name: "1" },
  { id: "2", name: "2" },
  { id: "3", name: "3" },
  { id: "4", name: "4" },
  { id: "5", name: "5" },
]

function skillOptions(vm: any, filterSkills: SkillObj[]): Option[] {
  return Object.values(SKILLS)
    .filter((skill) => !filterSkills.map((s) => s.id).includes(skill.id))
    .map((s) => {
      return {
        id: s.id as string,
        name: capitalize(vm.$t(s.id) as string),
      }
    })
    .sort((a, b) => (a.name < b.name ? -1 : 1))
}

@Component({
  components: {
    FLButton,
    FLSelect,
    IconButton,
  },
})
export class FLSkillInput extends Vue {
  @Prop({ default: () => [] }) filterSkills!: SkillObj[]

  skillId = ""
  rank = 1

  get skills(): Option[] {
    return [{ id: "", name: "" }, ...skillOptions(this, this.filterSkills)]
  }
  get ranks(): Option[] {
    return skillInputRanks
  }

  emitAdd() {
    const data = { skillId: this.skillId, rank: this.rank }
    this.$emit(ADDING_DONE, data)
  }
  emitCancel() {
    this.$emit(CANCEL)
  }
}

export default FLSkillInput
