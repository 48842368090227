
import FLSkillInput from "src/components/base/FLSkillInput.vue"

/**
 * Component for managing mount skills
 */
export {
  FLSkillInput,
  skillInputRanks,
} from "src/components/base/FLSkillInput.vue"
export default FLSkillInput
