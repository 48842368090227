
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

import FLButton from "@/components/base/FLButton.vue"
import FLSelect from "@/components/base/FLSelect.vue"
import IconButton from "@/components/base/IconButton.vue"
import ModalConfirm from "@/components/ModalConfirm.vue"

import { CharacterData } from "@/data/character/characterData"
import { SKILLS, SkillObj } from "@/skills"
import { Skill } from "@/types"

import {
  FLSkillInput as MountSkillInput,
  skillInputRanks as mountSkillInputRanks,
} from "./MountSkillInput.vue"

/** Component for managing mount skills */
@Component({
  components: {
    FLButton,
    FLSelect,
    IconButton,
    ModalConfirm,
    MountSkillInput,
  },
})
export default class MountSkills extends Vue {
  @Prop({ required: true }) charData!: CharacterData
  @Prop({ default: false }) viewOnly!: boolean

  adding = false
  editing: Skill | null = null
  mountSkillInputRanks = mountSkillInputRanks
  showConfirmDelete = false
  skillToRemove = ""

  addSkill({ skillId, rank }: { skillId: Skill; rank: number }) {
    this.adding = false
    const updatedSkill: SkillObj = { ...SKILLS[skillId], rank }

    const skillToUpdate = this.charData.mount.skills.find(
      (o) => o.id === skillId
    )
    if (!skillToUpdate) {
      this.charData.mount.skills.push(updatedSkill)
    } else {
      const index = this.charData.mount.skills.indexOf(skillToUpdate)
      this.charData.mount.skills[index] = updatedSkill
    }
  }

  askRemoveSkill(id: string) {
    this.skillToRemove = id
    this.showConfirmDelete = true
  }

  removeSkill(id: string) {
    this.charData.mount.skills = this.charData.mount.skills.filter(
      (skill) => skill.id !== id
    )
    this.showConfirmDelete = false
  }
}
