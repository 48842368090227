
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

import { CharacterData } from "@/data/character/characterData"
import { GearInventory } from "@/components/gear/GearInventory.vue"

@Component({
  components: {
    GearInventory,
  },
})
export default class MountInventory extends Vue {
  @Prop({ required: true }) charData!: CharacterData
  @Prop({ default: false }) viewOnly!: boolean

  get inventory() {
    return this.charData.mount.inventory
  }

  get gearWeight() {
    return this.inventory
      .map((item) => Number(item.weight))
      .reduce((val, sum) => val + sum, 0)
  }

  get gearWeightMax() {
    if (!this.charData.mount.strength) return 0
    const multiplier = this.charData.mount.mounted ? 2 : 4
    const charStrength = this.charData.mount.strength || 0
    return charStrength * multiplier
  }
}
