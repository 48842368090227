
// TODO: Look into http://youmightnotneedjs.com/#tabs for tabs

import Modal from "@/components/Modal.vue"
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"
import { CharacterData, CharacterTalent } from "@/data/character/characterData"

import FLButton from "@/components/base/FLButton.vue"
import FLNumberInput from "@/components/base/FLNumberInput.vue"

@Component({
  components: {
    FLButton,
    FLNumberInput,
    Modal,
  },
})
export default class XPModal extends Vue {
  @Prop({ required: true }) charData!: CharacterData

  charDataCopy: CharacterData = JSON.parse(JSON.stringify(this.charData))

  tabs: { active: boolean; name: any }[] = []
  newXP = 0
  newRep = 0

  get EXP_DESC(): string[] {
    return (this.$t("XP_DESCR") as string).split("\n\n")
  }
  get REP_DESC(): string[] {
    return (this.$t("REP_DESCR") as string).split("\n\n")
  }

  setActiveTab(activeIndex: number) {
    this.tabs.map((tab, index) => {
      index === activeIndex ? (tab.active = true) : (tab.active = false)
    })
  }

  emitNewXP() {
    this.charDataCopy.reputation += this.newRep
    this.charDataCopy.experience += this.newXP
    this.$emit("updated-chardata", this.charDataCopy)
    this.close()
  }

  mounted() {
    const tabNames = [this.$t("Experience"), this.$t("Reputation")]
    this.tabs = tabNames.map((name, index) => ({
      active: index === 0,
      name,
    }))
  }

  close() {
    this.$emit("close")
  }
}
