
/* eslint-disable no-console */
import { AGE, CLASS, KIN } from "@/keys"
import { capitalize } from "@/util/util"
import { getAgeType, getAgeRange, getReputation } from "@/age"
import { CLASS as PROFESSION } from "@/classes"
import Vue from "vue"
import { CharacterData } from "@/data/character/characterData"
import VueI18n from "vue-i18n"

export default Vue.extend({
  props: {
    data: {
      type: Object as () => CharacterData,
      required: true,
    },
    viewOnly: {
      type: Boolean,
    },
  },
  created() {
    this.characterData = this.data
  },
  data() {
    return {
      characterData: this.data as CharacterData,
    }
  },
  computed: {
    appearancePlaceholder(): string {
      return `${this.$t("Face")}, ${this.$t("Body")}, ${this.$t(
        "Clothing"
      )} etc`
    },
  },
})
