
import Vue from "vue"
import { ATTRIBUTE } from "@/keys"
import { getAttributePoints, getAgeType } from "@/age"
import { Attribute, KinName, Profession, Age } from "@/types"
import { CLASS as PROFESSION_MAP } from "@/classes"
import { KIN as KIN_MAP } from "@/kin"
import {
  CharacterData,
  CharacterMetaDataStatus,
} from "@/data/character/characterData"
import SvgIcon from "@/components/SvgIcon.vue"
import DiceModal from "@/components/dice/DiceModal.vue"
import { capitalize } from "@/util/util"
import { IDiceConfig } from "@/dice/diceTypes"
import IconButton from "@/components/base/IconButton.vue"
import { FLRangeSlider } from "src/components/base/FLRangeSlider.vue"

function getMaxAttribLevel(
  attribute: Attribute,
  kin: KinName | null,
  profession: Profession | null
): number {
  if (!kin || !profession) return 1
  const kinMod = KIN_MAP[kin].key_attribute === attribute ? 1 : 0
  const professionMod =
    PROFESSION_MAP[profession].key_attribute === attribute ? 1 : 0
  return 4 + kinMod + professionMod
}

export default Vue.extend({
  components: {
    DiceModal,
    FLRangeSlider,
    IconButton,
    SvgIcon,
  },
  props: {
    charData: {
      type: Object as () => CharacterData,
      required: true,
    },
    viewOnly: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  created() {
    if (!this.charData.attributeDmg) {
      const attributeDmg = { agility: 0, empathy: 0, strength: 0, wits: 0 }
      this.$set(this.charData, "attributeDmg", attributeDmg)
    }
    // TODO: Check if this is necessary
    this.charData.ageType = getAgeType(this.charData.age, this.charData.kin)
  },
  computed: {
    pointsLeft(): number {
      return this.pointsAvailable() - this.pointsSpent()
    },
    characterStatus(): CharacterMetaDataStatus {
      return this.charData.metadata.status
    },
    baseAttributesEditable(): boolean {
      return ["new", undefined, "freeEdit"].includes(this.characterStatus)
    },
    active(): boolean {
      return this.characterStatus === "active"
    },
  },
  methods: {
    attributeKeys() {
      return Object.keys(this.charData.attributes)
    },
    getAttribArray(attribute: Attribute): number[] {
      return [1, 2, 3, 4, 5, 6].slice(0, this.getMax(attribute))
    },
    getMax(attribute: Attribute): number {
      return getMaxAttribLevel(
        attribute,
        this.charData.kin,
        this.charData.profession
      )
    },
    pointsAvailable() {
      return getAttributePoints(
        getAgeType(this.charData.age, this.charData.kin)
      )
    },
    pointsSpent(): number {
      return Number(
        Object.entries(this.charData.attributes)
          .map((attribute) => attribute[1])
          .reduce((sum, value) => Number(sum) + Number(value))
      )
    },
    iconFor(attribute: Attribute): string {
      const map = {
        [ATTRIBUTE.STRENGTH]: "strong",
        [ATTRIBUTE.AGILITY]: "acrobatic",
        [ATTRIBUTE.WITS]: "brain",
        [ATTRIBUTE.EMPATHY]: "shaking-hands",
      }
      return map[attribute] || "close-button"
    },
    remaining(attributeId: string) {
      return (
        Number(this.charData.attributes[attributeId]) -
        Number(this.charData.attributeDmg[attributeId])
      )
    },
    handleInput(attributeId: string, value: number) {
      this.charData.attributeDmg[attributeId] =
        Number(this.charData.attributes[attributeId]) - value
    },
    roll(attributeId: Attribute, value: number) {
      const diceConf: IDiceConfig = {
        white: this.remaining(attributeId),
        red: null,
        black: null,
      }
      this.rollingDice = diceConf
      const attributeString = capitalize(String(this.$t(attributeId)))
      this.modalTitle = this.$t("Roll dice") + ": " + attributeString
    },
    resetRoll() {
      this.rollingDice = {}
      this.modalTitle = ""
    },
  },
  data() {
    return {
      ATTRIBUTE,
      rollingDice: {},
      modalTitle: "",
    }
  },
  watch: {
    charData: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        this.$emit("attributes-updated", this.charData.attributes)
      },
    },
  },
})
