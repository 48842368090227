
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"

import { CharData } from "@/data/character/characterData"

import XPModal from "@/components/XPModal.vue"
import ModalSpendXP from "@/components/ModalSpendXP.vue"
import Card from "@/components/Card.vue"
import FLButton from "@/components/base/FLButton.vue"

@Component({
  components: {
    Card,
    FLButton,
    XPModal,
    ModalSpendXP,
  },
})
export class SessionCard extends Vue {
  @Prop({ required: true }) charData!: CharData
  @Prop({ default: false }) viewOnly!: boolean

  showXPModal = false
  showSpendXPModal = false

  get status() {
    return this.charData.metadata.status
  }
  get saveStateId() {
    if (this.charData.metadata.status !== "active") return ""
    return "card_session"
  }

  handleNewCharData(charData: CharData) {
    this.$emit("updated-chardata", charData)
  }
}
export default SessionCard
