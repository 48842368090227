
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

import { CharData } from "@/data/character/characterData"
import { GearInventory } from "@/components/gear/GearInventory.vue"

@Component({
  components: {
    GearInventory,
  },
})
export class GearBackpack extends Vue {
  @Prop({ default: null }) charData!: CharData
  @Prop({ default: false }) viewOnly!: boolean
}

export default GearBackpack
