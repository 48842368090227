
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"
import { CharacterData } from "@/data/character/characterData"

import Card from "@/components/Card.vue"

@Component({
  components: {
    Card,
  },
})
export class NoteCard extends Vue {
  @Prop({ required: true }) charData!: CharacterData
  @Prop({ default: false }) viewOnly!: boolean

  get saveStateId() {
    if (this.charData.metadata.status !== "active") return ""
    return "card_notes"
  }
}

export default NoteCard
