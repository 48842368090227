
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"

import ExpandableSection from "@/components/ExpandableSection.vue"
import { FLSelect, Option } from "@/components/base/FLSelect.vue"
import { FLNumberInput } from "@/components/base/FLNumberInput.vue"
import { IconButton } from "@/components/base/IconButton.vue"

import { CharData } from "@/data/character/characterData"
import {
  Item,
  ItemArmor,
  ItemHelmet,
  ItemShield,
  ItemWeapon,
  ITEM_TYPE,
  Range,
} from "@/data/items/itemTypes"
import SvgIcon from "@/components/SvgIcon.vue"
import { GearRowDetail } from "@/components/gear/GearRowDetail.vue"
import { iconFor, equippable, iconForType } from "./util"

function getWeaponDetails(vm: Vue, weapon: ItemWeapon) {
  function getRange() {
    return vm.$t(Range[weapon.range])
  }

  function getFeatures() {
    return Object.entries(weapon.features)
      .filter((feature) => {
        return feature[1]
      })
      .map((feature) => String(vm.$t(feature[0])))
  }
  return [`${vm.$t("Range")}: ${getRange()}`, ...getFeatures()].join(", ")
}

function canAdd(c: CharData, t: ITEM_TYPE): boolean {
  return !!c.gear.inventory.find((i) => i.type === t && !i.equipped)
}

interface IRow {
  item: Item | undefined | null
  icon?: string
  bonusClick: () => void
  editClick: () => void
  addClick: () => void
  canAdd: boolean
  equipped: boolean
}

// FIXME: Handle bonus click
@Component({
  components: {
    SvgIcon,
    IconButton,
    GearRowDetail,
  },
})
export class GearEquippedRow extends Vue {
  @Prop({ default: null }) charData!: CharData
  @Prop({ default: null }) itemType!: ITEM_TYPE
  @Prop({ default: null }) item!: Item | null | undefined

  get row(): IRow {
    const row: IRow = this.item
      ? {
          item: this.item,
          icon: iconFor(this.item),
          bonusClick: () => this.$emit("bonusclick", this.item),
          equipped: !!this.item.equipped,
          editClick: () => this.$emit("edit", this.item),
          addClick: () => {},
          canAdd: canAdd(this.charData, this.itemType),
        }
      : {
          item: undefined,
          icon: iconForType(this.itemType),
          bonusClick: () => {},
          addClick: () => this.onEquip(this.itemType),
          editClick: () => {},
          canAdd: canAdd(this.charData, this.itemType),
          equipped: false,
        }
    return row
  }

  onUnequip(item: Item) {
    this.$emit("unequip", item)
  }

  onEquip(filterType: ITEM_TYPE | null) {
    this.$emit("equip", filterType)
  }
}

export default GearEquippedRow
