import { render, staticRenderFns } from "./TabBar.vue?vue&type=template&id=e5168ee2&scoped=true&"
import script from "./TabBar.vue?vue&type=script&lang=ts&"
export * from "./TabBar.vue?vue&type=script&lang=ts&"
import style0 from "./TabBar.vue?vue&type=style&index=0&id=e5168ee2&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e5168ee2",
  null
  
)

export default component.exports