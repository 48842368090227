
import Modal from "@/components/Modal.vue"
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"
import { CharacterData } from "@/data/character/characterData"

import FLNumberInput from "@/components/base/FLNumberInput.vue"
import FLButton from "@/components/base/FLButton.vue"

import { AnimalCompanionSkills } from "./AnimalCompanionSkills.vue"
import { AnimalCompanionStats } from "./AnimalCompanionStats.vue"

@Component({
  components: {
    FLButton,
    FLNumberInput,
    Modal,
    AnimalCompanionSkills,
    AnimalCompanionStats,
  },
})
export class AnimalCompanion extends Vue {
  @Prop({ required: true }) charData!: CharacterData
  @Prop({ default: false }) viewOnly!: boolean

  get hasMount() {
    return this.charData.mount.strength
  }
}

export default AnimalCompanion
