
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"
import { Conditions } from "@/data/character/characterData"

@Component({
  components: {},
})
export default class ConditionInput extends Vue {
  @Prop({ required: true }) conditions!: Conditions
  @Prop({ default: false }) viewOnly!: boolean

  conditionsCopy: Conditions = {
    cold: this.conditions.cold || false,
    starving: this.conditions.starving || false,
    dehydrated: this.conditions.dehydrated || false,
    tired: this.conditions.tired || false,
  }

  emit() {
    this.$emit("input", this.conditionsCopy)
  }
}
