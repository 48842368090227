
import Vue from "vue"
import Component from "vue-class-component"

import UrlStorage from "@/localStorage"

import { CharData } from "@/data/character/characterData"

import IconButton from "@/components/base/IconButton.vue"
import FLButton from "@/components/base/FLButton.vue"
import Modal from "@/components/Modal.vue"

function validate(url: string) {
  const regex = /^.*(\.jpg|\.jpeg|\.png|\.svg)\??/
  return url.match(regex)
}

function isHttps(url: string) {
  const regex = /^https:\/\//
  return url.match(regex)
}

function importAll(r: any) {
  return r.keys().map(r)
}

const IMAGES = importAll(
  require.context("../assets/portraits", false, /\.(png|jpe?g|svg)$/)
)
const PLACEHOLDER = require("@/assets/300x500-placeholder.png")
const getRandomPictureFromGallery = (gallery: string[]) =>
  gallery[~~(Math.random() * gallery.length)]

const AppProps = Vue.extend({
  props: {
    portrait: {
      required: true,
    },
    viewOnly: {
      type: Boolean,
    },
    charData: {
      default: null,
      type: Object,
    },
  },
  watch: {
    selected_portrait: {
      immediate: true,
      handler(newVal) {
        this.$emit("pickedPicture", newVal)
      },
    },
  },
})

@Component({
  components: {
    FLButton,
    IconButton,
    Modal,
  },
})
export default class PicturePicker extends AppProps {
  showModal: boolean = false
  showGallery: boolean = true
  showGetUrl: boolean = false
  urlStore = new UrlStorage()

  pictures: string[] = IMAGES
  imgUrl: string = ""
  externalImage: string = ""
  //"https://via.placeholder.com/400x600.png?text=Click+to+select+portrait"

  // for now, set a picture at random with getRandomPictureFromGallery
  selected_portrait = this.portrait || getRandomPictureFromGallery(IMAGES)

  showPicker() {
    if (this.viewOnly) return
    this.showModal = true
  }
  closePicker() {
    this.showModal = false
  }
  selectImage(id: string) {
    this.selected_portrait = id
    this.showModal = false
    // this.$emit("pickedPicture", this.selected_portrait)
  }
  urlButtonClicked() {
    this.urlStore.add(this.imgUrl)
    this.externalImage = this.imgUrl
    this.imgUrl = ""
  }
  deleteUrl(index: number) {
    this.urlStore.remove(index)
  }
  getPlaceHolder() {
    return this.pictures[~~(Math.random() * this.pictures.length)]
  }
  galleryClicked() {
    this.showGallery = true
    this.showGetUrl = false
  }
  getUrlClicked() {
    this.showGallery = false
    this.showGetUrl = true
  }

  get searchHref() {
    if (!this.charData) {
      return ""
    }
    const q = `${this.charData.kin}+${this.charData.profession}`
    return `https://duckduckgo.com/?q=${q}&ia=images&iax=images&atb=v119-1`
  }
  get warning(): string {
    const warnings: string[] = []
    if (!this.imgUrl) return ""
    if (!validate(this.imgUrl)) {
      warnings.push(
        "Url should have filetype 'jpg', 'png' or 'svg'; it might not work otherwise."
      )
    }
    if (!this.imgIsHttps) {
      warnings.push("Please only use secure links, beginning with 'https://'")
    }
    return warnings.join("\n")
  }
  get imgIsHttps(): boolean {
    return !!isHttps(this.imgUrl)
  }
}
