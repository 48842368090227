
import Vue from "vue"

import { Component, Prop } from "vue-property-decorator"
import { CharData } from "@/data/character/characterData"
import { GearStarting } from "@/components/gear/GearStarting.vue"
import { GearConsumables } from "@/components/gear/GearConsumables.vue"
import { GearMoney } from "@/components/gear/GearMoney.vue"
import { GearBackpack } from "@/components/gear/GearBackpack.vue"
import { GearEquipped } from "@/components/gear/GearEquipped.vue"
import { GearEncumbrance } from "src/components/gear/GearEncumbrance.vue"

@Component({
  components: {
    GearStarting,
    GearConsumables,
    GearEncumbrance,
    GearMoney,
    GearBackpack,
    GearEquipped,
  },
})
export default class GearPicker extends Vue {
  @Prop({ required: true }) charData!: CharData
  @Prop({ default: false }) viewOnly!: boolean
}
