
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"

import { IconButton } from "@/components/base/IconButton.vue"

import Modal from "@/components/Modal.vue"
import { GearTable } from "@/components/gear/GearTable.vue"
import { CharData } from "@/data/character/characterData"
import {
  Item,
  ItemArmor,
  ItemHelmet,
  ItemShield,
  ItemWeapon,
  ITEM_TYPE,
} from "@/data/items/itemTypes"
import { GearEquippedRow } from "./GearEquippedRow.vue"
import { GearRowDetail } from "@/components/gear/GearRowDetail.vue"
import { ModalEditItem } from "src/components/gear/item/ModalEditItem.vue"

import { iconFor, equippable } from "./util"
import { TSkillId } from "@/types"
import {
  convertLogToConfig,
  diceLogAttributes,
  diceLogItem,
  diceLogSkill,
  isRangedWeapon,
} from "@/dice/diceConfigurator"
import { FLButton } from "src/components/base/FLButton.vue"

@Component({
  components: {
    FLButton,
    GearEquippedRow,
    GearRowDetail,
    GearTable,
    IconButton,
    Modal,
    ModalEditItem,
  },
})
export class GearEquipped extends Vue {
  @Prop({ default: null }) charData!: CharData

  iconFor = iconFor
  selectItem: { items: Item[]; visible: boolean } = {
    items: [],
    visible: false,
  }

  get inventoryEquipped() {
    return this.charData.gear.inventory.filter((i) => i.equipped)
  }

  get inventoryNotEquipped() {
    return this.charData.gear.inventory.filter(
      (i) => equippable(i) && !i.equipped
    )
  }

  get equippedHelmet(): ItemHelmet | undefined {
    return this.inventoryEquipped.find(
      (item: Item): item is ItemHelmet => item.type === "helmet"
    )
  }

  get equippedShield(): ItemShield | undefined {
    return this.inventoryEquipped.find(
      (item): item is ItemShield => item.type === "shield"
    )
  }

  get equippedArmor(): ItemArmor | undefined {
    const found = this.inventoryEquipped.find(
      (item): item is ItemArmor => item.type === "armor"
    )
    return found
  }

  get equippedWeapons(): ItemWeapon[] {
    return this.inventoryEquipped.filter(
      (item): item is ItemWeapon => item.type === "weapon"
    )
  }

  clickedItem: Item | null = null
  itemClicked(item: Item) {
    this.clickedItem = item || null
  }
  updateItem(updatedItem: Item) {
    const newInventory = this.charData.gear.inventory.map((mitem) => {
      if (mitem.id !== updatedItem.id) return mitem
      return updatedItem
    })
    this.charData.gear.inventory = newInventory
    this.clickedItem = null
  }

  get rowData(): { item: Item | undefined; itemType: ITEM_TYPE; id: string }[] {
    return [
      //
      { id: "helmet", item: this.equippedHelmet, itemType: ITEM_TYPE.helmet },
      { id: "shield", item: this.equippedShield, itemType: ITEM_TYPE.shield },
      { id: "armor", item: this.equippedArmor, itemType: ITEM_TYPE.armor },
      ...this.equippedWeapons.map((w) => ({
        id: w.id,
        item: w,
        itemType: ITEM_TYPE.weapon,
      })),
      { id: "add-weapon", item: undefined, itemType: ITEM_TYPE.weapon },
    ]
  }

  // FIXME: Abstract away the dice config settings to dice engine that can
  // handle character talents, status etc
  armorClicked() {
    if (!this.equippedArmor && !this.equippedHelmet) return
    const black =
      (this.equippedHelmet || { bonus: 0 }).bonus +
      (this.equippedArmor || { bonus: 0 }).bonus
    this.$root.$emit("open-dice-modal", {
      title: this.$t("armor"),
      dice: { black },
      disablePush: true,
    })
  }

  shieldClicked() {
    if (!this.equippedShield) return
    const black = this.equippedShield.bonus
    const white =
      Number(this.charData.attributes.strength) -
      Number(this.charData.attributeDmg.strength)
    const red = this.charData.skills.melee.rank
    this.$root.$emit("open-dice-modal", {
      title: this.$t("parry"),
      dice: { white, black, red },
      disablePush: true,
    })
  }

  weaponClicked(item: ItemWeapon) {
    const skill: TSkillId = isRangedWeapon(item) ? "marksmanship" : "melee"

    const attributeLog = diceLogAttributes(this.charData, skill)
    const skillLog = diceLogSkill(this.charData, skill)
    const itemLog = diceLogItem(item)
    const dice = convertLogToConfig([attributeLog, skillLog, itemLog].flat())

    // const dice = { white: 3, red: 3, black: item.bonus } // FIXME: Get dice
    this.$root.$emit("open-dice-modal", {
      title: item.name,
      dice,
      disablePush: false,
    })
  }

  bonusClick(item: Item): void {
    switch (item.type) {
      case "shield":
        return this.shieldClicked()
      case "armor":
      case "helmet":
        return this.armorClicked()
      case "weapon":
        return this.weaponClicked(item)
      default:
        console.warn(`Cannot handle item type '${item.type}'`) // eslint-disable-line no-console
    }
  }

  onUnequip(item: Item) {
    this.equipItem(item, false)
  }

  onEquip(filterType: ITEM_TYPE | null) {
    const available = this.itemTypeAvailable(filterType)
    if (available.length === 1) {
      const item = available[0]
      this.equipItem(item, true)
    } else {
      this.selectItem = {
        visible: true,
        items: available,
      }
    }
  }

  itemTypeAvailable(filterType: ITEM_TYPE | null) {
    const _filter = filterType
      ? (item: Item) => item.type === filterType
      : (item: Item) => item
    const available = this.inventoryNotEquipped.filter(_filter)
    return available
  }

  equipItem(item: Item, equipped: boolean) {
    this.charData.gear.inventory = this.charData.gear.inventory.map((i) => {
      if (i.id === item.id) return { ...item, equipped }
      return i
    })
    this.selectItemClose()
  }

  selectItemClose() {
    this.selectItem = { items: [], visible: false }
  }
}

// FIXME: Fix dice rolling for weapons

export default GearEquipped
