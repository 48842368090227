
// TODO: Look into http://youmightnotneedjs.com/#tabs for tabs
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"

import FLButton from "@/components/base/FLButton.vue"
import FLInput from "@/components/base/FLInput.vue"
import { allItems } from "@/data/items/items"
import { capitalize } from "@/util/util"

@Component({
  components: {
    FLButton,
    FLInput,
  },
})
export default class ItemTemplatePicker extends Vue {
  activeFilter = ""

  get filteredItems() {
    return allItems.filter(
      (item) =>
        String(this.$t(item.id)).indexOf(this.activeFilter.toLowerCase()) >= 0
    )
  }
  get rangedWeapons() {
    return this.filteredItems.filter(
      (item) => item.type === "weapon" && item.skill === "marksmanship"
    )
  }
  get meleeWeapons() {
    return this.filteredItems.filter(
      (item) => item.type === "weapon" && item.skill === "melee"
    )
  }
  get armor() {
    return this.filteredItems.filter((item) => item.type === "armor")
  }

  get itemTemplates() {
    return [
      { title: this.$t("melee weapons"), items: this.meleeWeapons },
      { title: this.$t("ranged weapons"), items: this.rangedWeapons },
      {
        title: this.$t("helmets"),
        items: this.filteredItems.filter((item) => item.type === "helmet"),
      },
      { title: this.$t("armor"), items: this.armor },
      {
        title: this.$t("shields"),
        items: this.filteredItems.filter((item) => item.type === "shield"),
      },
      {
        title: this.$t("other"),
        items: this.filteredItems.filter(
          (item) => !["weapon", "shield", "armor", "helmet"].includes(item.type)
        ),
      },
    ]
  }

  selectTemplateItem(id: string) {
    const item = allItems
      .filter((item) => item.id === id)
      .map((item) => {
        return { ...item, name: capitalize(this.$t(item.id) as string) }
      })
      .pop()
    if (item) {
      this.$emit("template-picked", item)
    }
  }
}
