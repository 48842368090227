
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

import AttributesCreate from "@/components/sheet/attributes/AttributesCreate.vue"
import AttributesActive from "@/components/sheet/attributes/AttributesActive.vue"
import BaseSelector from "@/components/BaseSelector.vue"
import Conditions from "@/components/Conditions.vue"
import Card from "@/components/Card.vue"
import {
  CharacterData,
  validateAttributes,
} from "@/data/character/characterData"
import FLNumberInput from "@/components/base/FLNumberInput.vue"

@Component({
  components: {
    AttributesCreate,
    AttributesActive,
    BaseSelector,
    Card,
    Conditions,
    FLNumberInput,
  },
})
export class PropertiesCard extends Vue {
  @Prop({ required: true }) charData!: CharacterData
  @Prop({ default: false }) viewOnly!: boolean

  get status() {
    return this.charData.metadata.status
  }
  get valid() {
    return validateAttributes(this.charData)
  }
  get saveStateId() {
    if (this.charData.metadata.status !== "active") return ""
    return "card_properties"
  }
  // Attributes
  get attributesEdit(): boolean {
    return ["new", undefined, "freeEdit"].includes(this.status)
  }
  get showCardSign(): boolean {
    const editMode = ["new", undefined, "freeEdit"].includes(this.status)
    if (editMode) return true
    return false
  }
}

export default PropertiesCard
