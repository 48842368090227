
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"

import { CharData } from "@/data/character/characterData"
import { PROFESSION } from "@/classes"

@Component({
  components: {},
})
export class GearStarting extends Vue {
  @Prop({ default: null }) charData!: CharData

  PROFESSION = PROFESSION
  get currentProfession() {
    return this.charData.profession
  }
  get startingSilver() {
    if (!this.currentProfession) return 0
    const silver = PROFESSION[this.currentProfession].starting_resources.silver
    if (typeof silver === "number") {
      return `${this.$t("D")}${silver}`
    }
    return `${silver.nbr}${this.$t("D")}${silver.sides}`
  }
  get gearDescription() {
    if (!this.currentProfession) return ``
    return this.$t(PROFESSION[this.currentProfession].gear_description)
  }
}

export default GearStarting
