
import Modal from "@/components/Modal.vue"
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"
import { CharacterData } from "@/data/character/characterData"

import FLNumberInput from "@/components/base/FLNumberInput.vue"
import FLButton from "@/components/base/FLButton.vue"

import MountStats from "@/components/sheet/mount/MountStats.vue"
import MountSkills from "@/components/sheet/mount/MountSkills.vue"
import MountInventory from "@/components/sheet/mount/MountInventory.vue"

@Component({
  components: {
    FLButton,
    FLNumberInput,
    Modal,
    MountInventory,
    MountSkills,
    MountStats,
  },
})
export default class Mount extends Vue {
  @Prop({ required: true }) charData!: CharacterData
  @Prop({ default: false }) viewOnly!: boolean

  get hasMount() {
    return !!this.charData.mount.strength && !!this.charData.mount.name
  }
}
