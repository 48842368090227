
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"

import {
  IDiceModalState,
  MP_SAVE_CHAR,
  SET_DICE_MODAL,
  SET_PAGE_SUBTITLE,
} from "@/store/store-types"
import {
  CharData,
  CharacterMetaDataStatus,
} from "@/data/character/characterData"
import { COMBAT_BOX_KEY } from "src/util/const"

import SvgIcon from "@/components/SvgIcon.vue"
import FLButton from "@/components/base/FLButton.vue"
import ActionBar from "@/components/base/ActionBar.vue"
import BaseCard from "@/components/sheet/cards/BaseCard.vue"
import SessionCard from "@/components/sheet/cards/SessionCard.vue"
import TalentCard from "@/components/sheet/cards/TalentCard.vue"
import PortraitCard from "@/components/sheet/cards/PortraitCard.vue"
import SkillCard from "@/components/sheet/cards/SkillCard.vue"
import GearCard from "@/components/sheet/cards/GearCard.vue"
import MountCard from "@/components/sheet/cards/MountCard.vue"
import NoteCard from "@/components/sheet/cards/NoteCard.vue"
import StateCard from "@/components/sheet/cards/StateCard.vue"
import { CardCombat } from "@/components/sheet/cards/CombatCard.vue"
import AnimalCompanionCard from "@/components/sheet/cards/AnimalCompanionCard.vue"
import DiceModal from "@/components/dice/DiceModal.vue"

function stringChar(characterData: CharData) {
  return JSON.stringify(characterData)
}

@Component({
  components: {
    ActionBar,
    AnimalCompanionCard,
    BaseCard,
    CardCombat,
    DiceModal,
    FLButton,
    GearCard,
    MountCard,
    NoteCard,
    PortraitCard,
    SessionCard,
    SkillCard,
    StateCard,
    SvgIcon,
    TalentCard,
  },
})
export default class CharacterEditor extends Vue {
  @Prop({ required: true }) charData!: CharData
  @Prop({ default: false }) viewOnly!: boolean
  @Prop({ default: false }) isTemplateData!: boolean

  charDataCopyStr: string = stringChar(this.initialData)
  showDiceModal = false

  mounted() {
    this.setPageSubtitle()
  }
  destroyed() {
    this.$store.commit(SET_PAGE_SUBTITLE, "")
  }

  @Watch("charData.name")
  setPageSubtitle() {
    this.$store.commit(SET_PAGE_SUBTITLE, this.charData.name)
  }

  get initialData(): CharData {
    return (
      this.$characterStore.storedCharacter(this.charData.metadata.id) ||
      this.charData
    )
  }

  get status() {
    return this.charData.metadata.status
  }
  get charDataUpdated(): boolean {
    if (this.viewOnly) return false
    return stringChar(this.charData) !== this.charDataCopyStr
  }

  get closeText(): any {
    if (this.isTemplateData) return this.$t("Back")
    return this.$t("Close")
  }

  get saveDisabled(): boolean {
    return !this.charData.name
  }

  get combatCardEnabled(): boolean {
    return !!localStorage.getItem(COMBAT_BOX_KEY)
  }

  get diceModal(): IDiceModalState {
    return this.$store.state.diceModal || { open: false, diceConfig: {} }
  }
  closeDiceModal() {
    this.$store.commit(SET_DICE_MODAL, { open: false })
  }

  closeClicked() {
    if (
      this.charDataUpdated &&
      this.$characterStore.characterById(this.charData.metadata.id)
    ) {
      this.$characterStore.addCharacter(this.initialData) // reset data
    }
    this.$router.back()
  }

  closeSaveClicked() {
    this.saveClicked()
    this.$router.push("/")
  }

  saveClicked() {
    if (this.saveDisabled) return
    this.$store.commit(MP_SAVE_CHAR, this.charData)
    this.$characterStore.addCharacter(this.charData)
    this.charDataCopyStr = stringChar(this.charData)
  }

  setStatus(status: CharacterMetaDataStatus) {
    this.charData.metadata.status = status
  }

  handleCharDataUpdate(data: CharData) {
    this.$emit("chardata-updated", data)
  }
}
