
// TODO: Look into http://youmightnotneedjs.com/#tabs for tabs
import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"

import uuid1 from "uuid/v1"
import Modal from "@/components/Modal.vue"
import FLButton from "@/components/base/FLButton.vue"
import FLInput from "@/components/base/FLInput.vue"
import { FLSelect, Option } from "@/components/base/FLSelect.vue"
import { allItems } from "@/data/items/items"
import {
  Item,
  ItemWeapon,
  ITEM_TYPE,
  WEAPON_CATEGORY,
} from "@/data/items/itemTypes"
import ItemTemplatePicker from "@/components/gear/ItemTemplatePicker.vue"
import FLNumberInput from "@/components/base/FLNumberInput.vue"
import TabBar from "@/components/base/TabBar.vue"
import { DiceSides, DiceTypes } from "@/types"

function defaultItem(): Item {
  return {
    bonus: 0,
    bonusType: null,
    equipped: false,
    name: "",
    weight: 1,
    type: "",
    id: uuid1(),
  }
}

function makeTempItem(orig: Item | null) {
  const item: Item = JSON.parse(JSON.stringify(orig || defaultItem()))
  if (!item.artifactDice) {
    item.artifactDice = [{ sides: 0, color: "green", nbrDice: 0 }]
  }
  return item
}

/** A form handler for an Item.
 *  NB: The item will be mutated by this component, so make sure to send in a
 *  copy if appropriate */
@Component({
  components: {
    FLButton,
    FLInput,
    FLNumberInput,
    FLSelect,
    ItemTemplatePicker,
    Modal,
    TabBar,
  },
})
export class ItemForm extends Vue {
  @Prop() item!: Item

  get isWeapon(): boolean {
    return this.item.type === "weapon"
  }

  weaponCategories: (Option & { id: WEAPON_CATEGORY })[] = [
    { id: WEAPON_CATEGORY.axe },
    { id: WEAPON_CATEGORY.blunt },
    { id: WEAPON_CATEGORY.knife },
    { id: WEAPON_CATEGORY.polearm },
    { id: WEAPON_CATEGORY.sword },
    { id: WEAPON_CATEGORY.bow },
    { id: WEAPON_CATEGORY.crossbow },
    { id: WEAPON_CATEGORY.thrown },
  ]

  changeItemType(t: ITEM_TYPE) {
    if (t === ITEM_TYPE.weapon) {
      ;(this.item as ItemWeapon).features = {}
    }
    this.item.type = t
  }

  pageFor(itemType: string) {
    const loc = this.$i18n.locale
    if (itemType === "weapon") {
      return loc === "se" ? "98+" : "101+"
    }
    if (itemType === "armor") {
      return loc === "se" ? "103" : "106"
    }
    if (itemType === "helmet") {
      return loc === "se" ? "103" : "106"
    }
    if (itemType === "shield") {
      return loc === "se" ? "102" : "106"
    }
    if (itemType === "other") {
      return loc === "se" ? "176+" : "182+"
    }
    return "?"
  }

  get artifactDiceOptions(): Option[] {
    return [
      { id: 0 },
      { id: 8, name: String(this.$t("D")) + 8 },
      { id: 10, name: String(this.$t("D")) + 10 },
      { id: 12, name: String(this.$t("D")) + 12 },
    ]
  }

  get artifactDice(): DiceSides {
    return this.item?.artifactDice?.[0]?.sides || 0
  }

  onChangeArtifact(s: string) {
    const n = Number(s)
    if (![12, 10, 8].includes(n)) {
      this.item.artifactDice = []
      return
    }
    const sides: DiceSides = n as DiceSides
    const color: DiceTypes = n === 12 ? "orange" : n === 10 ? "blue" : "green"
    this.item.artifactDice = [{ sides, color, nbrDice: 1 }]
  }
}

export default ItemForm
