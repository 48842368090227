
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"

import { FLNumberInput } from "@/components/base/FLNumberInput.vue"
import { CharData } from "@/data/character/characterData"
import SvgIcon from "@/components/SvgIcon.vue"

@Component({
  components: {
    FLNumberInput,
    SvgIcon,
  },
})
export class GearMoney extends Vue {
  @Prop({ default: null }) charData!: CharData
  @Prop({ default: false }) viewOnly!: boolean
}

export default GearMoney
