
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"

import { Item, ItemWeapon, ITEM_TYPE, Range } from "@/data/items/itemTypes"
import SvgIcon from "@/components/SvgIcon.vue"
import { iconFor, iconForType } from "./util"

function getWeaponDetails(vm: Vue, weapon: ItemWeapon) {
  function getRange() {
    return vm.$t(Range[weapon.range])
  }

  function getFeatures() {
    return Object.entries(weapon.features)
      .filter((feature) => {
        return feature[1]
      })
      .map((feature) => String(vm.$t(feature[0])))
  }
  return [`${vm.$t("Range")}: ${getRange()}`, ...getFeatures()].join(", ")
}

@Component({
  components: {
    SvgIcon,
  },
})
export class GearRowDetail extends Vue {
  @Prop({ default: () => {} }) item!: Item
  @Prop({ default: "" }) icon!: string
  @Prop({ default: "" }) type!: ITEM_TYPE
  @Prop({ default: true }) showDetails!: boolean
  @Prop({ default: true }) underline!: boolean
  @Prop({ default: true }) showBonus!: boolean

  get details(): string {
    if (!this.showDetails) return ""
    if (this.item?.type !== "weapon") return ""
    return getWeaponDetails(this, this.item)
  }

  get comment() {
    return this.item?.comment
  }

  get name(): string {
    return this.item?.name || ""
  }

  get mIcon(): string {
    if (this.icon) return this.icon
    if (this.item) return iconFor(this.item)
    if (this.type) return iconForType(this.type)
    return ""
  }

  get artifactDiceSides(): number {
    if (this.item?.artifactDice?.[0]) {
      return this.item.artifactDice[0].sides
    }
    return 0
  }

  get missingCategory(): boolean {
    return this.item?.type === "weapon" && !this.item?.category
  }
}

export default GearRowDetail
