
import Vue from "vue"
import VueI18n from "vue-i18n"
/* eslint-disable no-console */
import { CLASS, KIN } from "@/keys"
import { capitalize } from "@/util/util"
import { getAgeType, getAgeRange, getReputation } from "@/age"
import { CLASS as PROFESSION } from "@/classes"
import { CharacterData } from "@/data/character/characterData"

import FLInput from "@/components/base/FLInput.vue"

export default Vue.extend({
  components: {
    FLInput,
  },
  props: {
    data: {
      type: Object as () => CharacterData,
      required: true,
    },
    viewOnly: {
      type: Boolean,
      default: false,
      required: false,
    },
    // Functions
    capitalize: {
      default: () => capitalize,
    },
  },
  created() {
    this.mdata = this.data
  },
  data() {
    return {
      mdata: {} as CharacterData,
      kin_select: [
        { id: KIN.ELF, name: "Elf" },
        { id: KIN.DWARF, name: "Dwarf" },
        { id: KIN.GOBLIN, name: "Goblin" },
        { id: KIN.HALFELF, name: "Half-elf" },
        { id: KIN.HALFLING, name: "Halfling" },
        { id: KIN.HUMAN, name: "Human" },
        { id: KIN.ORC, name: "Orc" },
        { id: KIN.WOLFKIN, name: "Wolfkin" },
      ],
      class_select: [
        { id: CLASS.CHAMPION, name: "champion" },
        { id: CLASS.DRUID, name: "druid" },
        { id: CLASS.FIGHTER, name: "fighter" },
        { id: CLASS.HUNTER, name: "hunter" },
        { id: CLASS.MINSTREL, name: "minstrel" },
        { id: CLASS.PEDDLER, name: "peddler" },
        { id: CLASS.RIDER, name: "rider" },
        { id: CLASS.ROGUE, name: "rogue" },
        { id: CLASS.SORCERER, name: "sorcerer" },
      ],
      selected_sex: "",
      PROFESSION,
    }
  },
  computed: {
    ageType(): VueI18n.TranslateResult {
      if (!this.mdata.age) return ""
      return `(${this.$t(getAgeType(this.mdata.age, this.mdata.kin))})`
    },
    reputation(): number {
      return this.mdata.reputation || this.calcRep()
    },
    disabled(): boolean {
      return this.data.metadata.status === "active" || this.viewOnly
    },
    freeEdit(): boolean {
      return this.data.metadata.status === "freeEdit"
    },
  },
  watch: {
    mdata: {
      // immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        this.mdata.reputation =
          this.mdata.metadata.status !== "new"
            ? this.mdata.reputation
            : this.calcRep()
        this.mdata.ageType = getAgeType(this.mdata.age, this.mdata.kin)
        this.$emit("basedata-updated", this.mdata)
      },
    },
  },
  methods: {
    calcRep() {
      return getReputation(getAgeType(this.mdata.age, this.mdata.kin))
    },
    nameSuggestion() {
      // TODO Get suggested names from docs, output them based on selected kin
      return ""
    },
    ageRange() {
      return getAgeRange(this.mdata.kin)
    },
  },
})

/*

    <div class="cell" style="display: none;">
      <label for="sex">{{ capitalize($t("sex")) }}</label>
      <span id="sex">
        <input
          type="radio"
          id="male"
          name="sex"
          v-model="mdata.sex"
          value="male"
        />
        <label for="male">{{ $t("Male") }}</label>
        <input
          type="radio"
          id="female"
          name="sex"
          v-model="mdata.sex"
          value="female"
        />
        <label for="female">{{ $t("Female") }}</label>
      </span>
    </div>
*/
