
// TODO: Look into http://youmightnotneedjs.com/#tabs for tabs

import Modal from "@/components/Modal.vue"
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"
import {
  CharacterData,
  CharacterTalent,
  calcCharacterXP,
} from "@/data/character/characterData"

import SkillSelector from "@/components/SkillSelector.vue"
import TalentSelector from "@/components/TalentSelector.vue"
import FLButton from "@/components/base/FLButton.vue"

@Component({
  components: {
    FLButton,
    Modal,
    SkillSelector,
    TalentSelector,
  },
})
export default class XPModal extends Vue {
  @Prop({ required: true }) charData!: CharacterData
  @Prop({ default: "" }) width!: string
  @Prop({ default: false }) show!: boolean

  showXPModal = this.show

  charDataCopy: CharacterData = JSON.parse(JSON.stringify(this.charData))
  originalStatus = this.charData.metadata.status

  get xpSpent() {
    return (
      calcCharacterXP(this.charDataCopy) -
      this.charDataCopy.metadata.xpAtCreation
    )
  }
  emitCharData() {
    const charData = { ...this.charDataCopy }
    charData.metadata.status = this.originalStatus
    this.$emit("updated-chardata", charData)
    this.close()
  }

  mounted() {
    this.charDataCopy.metadata.status = "levelup"
  }

  updateTalents(talents: CharacterTalent[]) {
    this.$set(this.charDataCopy, "talents", talents)
  }

  close() {
    this.$emit("close")
  }

  get title() {
    return `${this.$t("XP")}: ${this.charDataCopy.experience}`
  }
}
