
import Vue from "vue"
import { Component, Prop, Watch } from "vue-property-decorator"

import ExpandableSection from "@/components/ExpandableSection.vue"
import { FLSelect, Option } from "@/components/base/FLSelect.vue"
import { IconButton } from "@/components/base/IconButton.vue"
import { CharData } from "@/data/character/characterData"
import { PROFESSION, StartResource } from "@/classes"
import { IDiceModalState, SET_DICE_MODAL } from "src/store/store-types"
import { DiceSides } from "src/types"
import { IDiceConfig } from "src/dice/diceTypes"
import { isNumber } from "util"

@Component({
  components: {
    ExpandableSection,
    FLSelect,
    IconButton,
  },
})
export class GearConsumables extends Vue {
  @Prop({ default: null }) charData!: CharData
  @Prop({ default: false }) viewOnly!: boolean

  get diceOptions(): Option[] {
    return [
      { id: "0" },
      { id: "6", name: String(this.$t("D")) + 6 },
      { id: "8", name: String(this.$t("D")) + 8 },
      { id: "10", name: String(this.$t("D")) + 10 },
      { id: "12", name: String(this.$t("D")) + 12 },
    ]
  }
  get food() {
    return this.charData.gear.consumables.food
  }
  get water() {
    return this.charData.gear.consumables.water
  }
  get arrows() {
    return this.charData.gear.consumables.arrows
  }
  get torches() {
    return this.charData.gear.consumables.torches
  }

  click(name: "food" | "water" | "arrows" | "torches") {
    let dice: DiceSides
    let title: string
    switch (name) {
      case "food":
        dice = this.food
        title = this.$t("Food") as string
        break
      case "water":
        dice = this.water
        title = this.$t("Water") as string
        break
      case "arrows":
        dice = this.arrows
        title = this.$t("Arrows") as string
        break
      case "torches":
        dice = this.torches
        title = this.$t("Torches") as string
        break
    }
    if (!dice) return

    const diceConfig = getDiceConfig(dice)
    const state: Partial<IDiceModalState> = { diceConfig, title, open: true }
    this.setDiceModal(state)
  }
  setDiceModal(state: Partial<IDiceModalState>) {
    this.$store.commit(SET_DICE_MODAL, state)
  }

  startingConsumable(consumable: keyof Exclude<StartResource, "silver">) {
    if (!this.charData.profession) return 0
    const startConsumable =
      PROFESSION[this.charData.profession].starting_resources[consumable] || 0
    if (typeof startConsumable !== "number") {
      return 0
    }
    return startConsumable
  }

  @Watch("charData.profession", { immediate: true })
  setConsumables() {
    if (!this.charData.profession || this.charData.metadata.status !== "new") {
      return
    }
    this.charData.gear.consumables.food = this.startingConsumable("food")
    this.charData.gear.consumables.water = this.startingConsumable("water")
    this.charData.gear.consumables.arrows = this.startingConsumable("arrows")
    this.charData.gear.consumables.torches = this.startingConsumable("torches")
  }
}

function getDiceConfig(dice: DiceSides): IDiceConfig {
  switch (dice) {
    case 6:
      return { red: 1 }
    case 8:
      return { green: 1 }
    case 10:
      return { blue: 1 }
    case 12:
      return { orange: 1 }

    default:
      return {}
  }
}

export default GearConsumables
